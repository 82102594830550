var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "10"
    }
  }, [_c('section', {
    staticClass: "d-flex items-center"
  }, [_c('b-form-group', {
    staticClass: "mr-2",
    attrs: {
      "label": _vm.sum_date == 2 ? "Tanggal Awal" : 'Tanggal'
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_awal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_awal", $$v);
      },
      expression: "form.tgl_awal"
    }
  })], 1), _vm.sum_date == 2 ? _c('b-form-group', {
    staticClass: "mr-2",
    attrs: {
      "label": "Tanggal Akhir"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tgl_akhir,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_akhir", $$v);
      },
      expression: "form.tgl_akhir"
    }
  })], 1) : _vm._e(), _vm._t("default")], 2)]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "-"
    }
  }, [_vm._t("dropdown-item", [_vm.type_export.length > 1 ? _c('b-dropdown', {
    attrs: {
      "variant": "outline-primary",
      "text": "Export"
    }
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onExport('pdf');
      }
    }
  }, [_vm._v("Export ke PDF")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onExport('excel');
      }
    }
  }, [_vm._v("Export ke Excel")])], 1) : _vm._e(), _vm.type_export.length == 1 ? _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onExport('pdf');
      }
    }
  }, [_vm._v("Export")]) : _vm._e()], {
    "form": _vm.form
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }